@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
$sidebar-bg-color: #221b12c6;
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$submenu-bg-color-collapsed: rgba(29, 29, 29, 0.95);
$sidebar-color: rgba(236, 236, 236, 0.938);
$highlight-color: rgb(255, 223, 83);
$icon-bg-color: rgba(255, 255, 255, 0.2);
$icon-size: 34px;
$sidebar-width: 210px; 

@import '~react-pro-sidebar/dist/scss/styles.scss';
body {
  margin: 0;
  height: 100vh;
  color: #353535;
  font-family: merriweather, serif;
  background-color: white;
}

#root {
  height: 100%;
}

.app {
  height: 100%;
  display: flex;
  position: relative;

  .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #353535;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    display: none;
    z-index: 1000;
  }

  .sidebar-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .sidebar-btn {
      padding: 1px 15px;
      border-radius: 40px;
      background: rgba(255, 255, 255, 0.05);
      color: rgba(236, 236, 236, 0.938);
      text-decoration: none;
      margin: 0 auto;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: ellipsis;
      overflow: hidden;
      span {
        margin-left: 5px;
        font-size: 13px;
      }
      &:hover {
        color: $highlight-color;
      }
    }
  }

  .collapsed {
    .sidebar-btn {
      display: inline-block;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      line-height: 40px;
      padding: 0;
    }
  }

  main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    header {
      margin-bottom: 0px;
      border-bottom: 1px solid #efefef;
      h1 {
        display: flex;
        align-items: center;
        transform: translateX(-20px);
      }
    }

    footer {
      padding: 5px; 
      color: rgb(85, 80, 76);
      text-align: center;
      a {
        text-decoration: none;
      color: rgb(85, 80, 76);
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .social-bagdes {
    img {
      margin: 5px;
    }
  }

  .block {
    display: flex;
    margin-bottom: 24px;
    font-size: 14px;
    color: #545454;
    > span {
      margin-left: 10px;
    }
  }

  &.rtl {
    direction: rtl;
    text-align: right;

    header { 
      h1 {
        transform: translateX(0px);
      }
    }
    .block {
      direction: rtl;
      > span {
        margin-left: 0;
        margin-right: 10px;
      }
    }
    .sidebar-btn-wrapper {
      .sidebar-btn {
        span {
          margin-left: 0;
          margin-right: 5px;
        }
      }
    }
  }

  @media (max-width: 767.98px) {
    overflow-x: hidden;
    header {
      h1 {
        font-size: 24px;
      }
    }
  }
  @media (max-width: $breakpoint-md) {
    .btn-toggle {
      display: flex; 
      position: absolute; 
      top: 10px; 
      left: 10px;  
    }

    &.rtl {
      .btn-toggle {
        margin-left: auto;
      }
    }
  }
}

.badge {
  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 15px;
  &.red {
    color: #ffffff;
    background: #d63030;
  }
  &.gray {
    color: #ffffff;
    background: #777777;
  }
  &.yellow {
    color: #000000;
    background: #ffd331;
  }
}

.container {
  margin-bottom: 10px; 
}

@media only screen and (max-width: 700px) {
  .b1 {
    order: 2;
  }
  .b2 {
    order: 1;
  }
  .b3 {
    order: 3;
  }
  .b4 {
    order: 4;
  }
}

@media only screen and (min-width: 768px) {
  .p1 {
    order: 1; 
  }
  .p2 {
    order: 2; 
  }
  .p3 {
    order: 3; 
  }
  .p4 {
    order: 4; 
  }
  .p5 {
    order: 5; 
  }
  .p6 {
    order: 6; 
  }
  .p7 {
    order: 7; 
  }
  .p8 {
    order: 8; 
  }
  .p9 {
    order: 9; 
  }
  .p10 {
    order: 10; 
  }
  .p11 {
    order: 11; 
  }
  .p12 {
    order: 12; 
  }
}

@media only screen and (min-width: 992px) {
  .p1 {
    order: 1; 
  }
  .p2 {
    order: 2; 
  }
  .p3 {
    order: 4; 
  }
  .p4 {
    order: 3; 
  }
  .p5 {
    order: 5; 
  }
  .p6 {
    order: 6; 
  }
  .p7 {
    order: 8; 
  }
  .p8 {
    order: 7; 
  }
  .p9 {
    order: 9; 
  }
  .p10 {
    order: 10; 
  }
  .p11 {
    order: 11; 
  }
  .p12 {
    order: 12; 
  }
}

@media only screen and (min-width: 1280px) {
  .p1 {
    order: 1; 
  }
  .p2 {
    order: 2; 
  }
  .p3 {
    order: 3; 
  }
  .p4 {
    order: 4; 
  }
  .p5 {
    order: 6; 
  }
  .p6 {
    order: 5; 
  }
  .p7 {
    order: 8; 
  }
  .p8 {
    order: 7; 
  }
  .p9 {
    order: 9; 
  }
  .p10 {
    order: 10; 
  }
  .p11 {
    order: 11; 
  }
  .p12 {
    order: 12; 
  }
}

.contact-icon:hover {
  color: white; 
  background-color: #383838;
  border-radius: 30px; 
  padding: 5px; 
}

.contact-icon {
  padding: 5px; 
  color: #383838; 
}

.aside-icon {
  display: flex; 
  flex-direction: row; 
  justify-content: center;
  text-decoration: none;
  color: rgba(236, 236, 236, 0.938);
}

.aside-icon:hover{
  display: flex; 
  flex-direction: row; 
  justify-content: center;
  text-decoration: none;
  color: $highlight-color;
}


.aboutServiceLink {
  color: #1c1a1a; 
}

.aboutServiceLink:hover {
  color: #1c1a1a; 
  border-top: 1px solid #383838;
  border-bottom: 1px solid #383838;
  text-decoration: none;
}